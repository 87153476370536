@import "variables";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background-color: $backgroundColor-Type5;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


/** Font Style **/

@font-face {
    font-family: "Roboto";
    src: local("../public/Assets/fonts"), url("../public/Assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: local("../public/Assets/fonts"), url("../public/Assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: local("../public/Assets/fonts"), url("../public/Assets/fonts/Roboto-Medium.ttf") format("truetype");
}

.font-xxlarge {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 30px;
}

.font-xlarge {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 22px;
}

.font-x-min-large {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 24px;
}

.font-mlarge {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 20px;
}

.font-large2 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 18px;
}

.font-large {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 15px;
}

.font-large-md {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
}

.font-medium {
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 500;
}

.font-mediumBold {
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 600;
}

.font-regular-1 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 16px;
}

.font-regular {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
}

.font-small {
    font-family: "Roboto";
    font-size: 13px;
}

.font-small2 {
    font-family: "Roboto";
    font-size: 14px;
}
.font-small17 {
    font-family: "Roboto";
    font-size: 20px;
}

.font-smallBold {
    font-family: "Roboto";
    font-size: 13px;
}

.font-xsmall {
    font-family: "Roboto";
    font-size: 12px;
}

.font-xsmall-setting {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 600;
}

.font-xsmall-1 {
    font-family: "Roboto";
    font-size: 11px;
}

.font-xxsmall {
    font-family: "Roboto";
    font-size: 10px;
}

.w100 {
    width: 100% !important;
}


/**---------------------------- margin ----------------------------**/

.cmr-30 {
    margin-right: 30px;
}

.cmr-20 {
    margin-right: 20px;
}

.cmr-15 {
    margin-right: 15px;
}

.cmr-12 {
    margin-right: 12px;
}

.cmr-10 {
    margin-right: 10px;
}

.cmr-5 {
    margin-right: 5px;
}

.cmr-12 {
    margin-right: 12px;
}

.cmr-0 {
    margin-right: 0px;
}

.cml-0 {
    margin-left: 0px !important;
}

.cml-30 {
    margin-left: 30px;
}

.cml-18 {
    margin-left: 18px;
}

.cml-20 {
    margin-left: 20px;
}

.cml-15 {
    margin-left: 15px;
}

.cml-12 {
    margin-left: 12px;
}

.cml-10 {
    margin-left: 10px;
}

.cml-5 {
    margin-left: 5px;
}

.cml-8 {
    margin-left: 8px;
}

.cmtRem-21 {
    margin-top: 21rem !important;
}

.cmtRem-24 {
    margin-top: 24rem !important;
}

.cmt-60 {
    margin-top: 60px;
}

.cmt-83 {
    margin-top: 83px;
}

.cmt-30 {
    margin-top: 30px;
}

.cmt-29 {
    margin-top: 29%;
}

.cmt-47 {
    margin-top: 47% !important;
}

.cmt-25 {
    margin-top: 25px !important;
}

.cmt-20 {
    margin-top: 20px;
}

.cmt-15 {
    margin-top: 15px !important;
}

.cmt-10 {
    margin-top: 10px;
}

.cmb-3 {
    margin-bottom: 3px !important;
}

.cmb-6 {
    margin-bottom: 6px;
}

.cmb-8 {
    margin-bottom: 8px;
}

.cmb-38 {
    margin-bottom: 38px;
}

.cmb-90 {
    margin-bottom: 90px !important;
}

.cmb-30 {
    margin-bottom: 30px;
}

.cmb-55 {
    margin-bottom: 55px;
}

.cmb-20 {
    margin-bottom: 20px;
}

.cmb-15 {
    margin-bottom: 15px;
}

.cmb-17 {
    margin-bottom: 17px;
}

.cmb-10 {
    margin-bottom: 10px;
}


/**---------------------------- padding ----------------------------**/

.cpr-25 {
    padding-right: 25px !important;
}

.cpr-20 {
    padding-right: 20px;
}

.cpr-15 {
    padding-right: 15px !important;
}

.cpr-12 {
    padding-right: 12px;
}

.cpr-10 {
    padding-right: 10px;
}

.cpr-7 {
    padding-right: 7px;
}

.cpr-8 {
    padding-right: 8px !important;
}

.cpr-5 {
    padding-right: 5px;
}

.cpr-2 {
    padding-right: 2px !important;
}

.cp-custom {
    z-index: 2;
    padding: 0 19px 0 18px !important;
}

.cp-customwithBD {
    z-index: 0;
    padding: 0 19px 0 18px !important;
}

.cpl-25 {
    padding-left: 25px !important;
}

.cpl-20 {
    padding-left: 20px;
}

.cpl-12 {
    padding-left: 12px;
}

.cpl-15 {
    padding-left: 15px !important;
}

.cpl-10 {
    padding-left: 10px;
}

.cpl-7 {
    padding-left: 7px;
}

.cpl-8 {
    padding-left: 8px !important;
}

.cpl-5 {
    padding-left: 5px;
}

.cpl-2 {
    padding-left: 2px !important;
}

.cpt-20 {
    padding-top: 20px;
}

.cpt-22 {
    padding-top: 22px;
}

.cpt-15 {
    padding-top: 15px !important;
}

.cpt-10 {
    padding-top: 10px !important;
}

.cpt-7 {
    padding-top: 7px;
}

.cpt-5 {
    padding-top: 5px;
}

.cpt-2 {
    padding-top: 2px !important;
}

.cpb-20 {
    padding-bottom: 20px !important;
}

.cpb-22 {
    padding-bottom: 22px;
}

.cpb-15 {
    padding-bottom: 15px !important;
}

.cpb-10 {
    padding-bottom: 10px !important;
}

.cpb-7 {
    padding-bottom: 7px;
}

.cpb-8 {
    padding-bottom: 8px !important;
}

.cpb-5 {
    padding-bottom: 5px;
}

.cpb-3 {
    padding-bottom: 3px !important;
}

.cpb-2 {
    padding-bottom: 2px !important;
}

.cpt-170 {
    padding-top: 170px;
}


/**---------------- scroll hide ------------**/

.scroll-hide {
    overflow: hidden;
    position: sticky;
}


/**---------------- text color--------------**/

.textColor-Type1 {
    color: $textColor-Type1;
}

.textColor-Type2 {
    color: $textColor-Type2;
}

.textColor-Type3 {
    color: $textColor-Type3;
}

.textColor-Type4 {
    color: $textColor-Type4;
}

.textColor-Type5 {
    color: $textColor-Type5;
}

.textColor-Type6 {
    color: $textColor-Type6;
}

.textColor-Type7 {
    color: $textColor-Type7;
}

.textColor-Type8 {
    color: $textColor-Type8;
}

.textColor-LoginError {
    color: $textColor-LoginError;
}

.textColor-Error {
    color: $textColor-LoginError;
}


/**---------------- background color--------------**/

.backgroundColor-Type1 {
    background-color: $backgroundColor-Type1;
}

.backgroundColor-Type2 {
    background-color: $backgroundColor-Type2;
}

.backgroundColor-Type3 {
    background-color: $textColor-Type2;
}

.backgroundColor-Type4 {
    background-color: $backgroundColor-Type4;
}

.backgroundColor-Type5 {
    background-color: $backgroundColor-Type5;
}

.backgroundColor-Type7 {
    background-color: $textColor-Type7;
}

.backgroundColor-Type6 {
    background-color: $backgroundColor-Type6;
}

.backgroundColor-Type8 {
    background-color: $backgroundColor-Type7;
}

.backgroundColor-Disable {
    background-color: $backgroundColor-Disable;
}

.backgroundColor-Gradient {
    background-image: linear-gradient(to right, $textColor-Type6, $textColor-Type5);
}

.backgroundColor-grey {
    background-color: grey;
}


/**---------------- border color--------------**/

.borderColor-Type1 {
    border-color: $textColor-Type6;
}

.borderColor-Type2 {
    border-color: $textColor-Type6;
}

.borderColor-Type2 {
    border-color: $textColor-Type2;
}


/**------------------ border-------------------**/

.bl-max {
    border-left: 2px solid $borderColor-Type1;
}

.bb-max {
    width: 100%;
    opacity: 0.1;
    border-bottom: 1px solid $textColor-Type1;
}


/**------------------------------ main classes ----------------------------------------**/

.floatRight {
    float: right;
}

.rotate {
    transform: rotate(-180deg);
}

.floatleft {
    float: left;
}

.alignEnd {
    text-align: end
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.input-container {
    p {
        margin-bottom: 0rem;
    }
}

.forminput {
    border-radius: 10px;
    border-style: hidden;
    height: 45px;
    width: 100%;
}

.eyeImage {
    position: absolute;
}

.errorfocusinput {}

.errorfocusinput:focus {
    outline: none !important;
    border: none;
}

.focusinput {}

.focusinput:focus {
    outline: none !important;
    border: 2px solid $textColor-Type6;
}

.formsubmit {
    display: flex;
    justify-content: center;
    border-radius: 25px;
    border-style: hidden;
    line-height: 40px;
    width: 100%;
    @extend .textColor-Type2;
    text-align: center;
}

.formsubmit-container {
    display: flex;
    justify-content: space-evenly;
}

.formforgotpassContainer {
    display: flex;
    justify-content: center;
    line-height: 19px;
    @extend .textColor-Type3;
}

.forgot {
    background: rgba(79, 223, 137, 0.2);
    border: 1px solid #4FDF89;
    border-radius: 20px;
    padding: 20px;
}

.forgot label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    text-align: center;

    color: #000000;
}

.mainPageType2 {
    height: calc(100vh - (50px + 95px));
}

.mainPageType1 {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

.mainPageType3 {
    height: calc(100vh - (50px + 47px));
}

.FulfillmentScreen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 65px;

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        padding-bottom: 10px;
        padding-left: 25px;
    }
}

.headerMain {
    // height: 50px;
    left: 0px;
    top: 0px;
    @extend .borderColor-Type1;
    border-width: 2px;
    border-bottom-style: double;
}

.headerMain2 {
    display: flex;
    justify-content: space-between;
}

.lineBreak {
    line-height: normal;
}

.hide {
    display: none;
    opacity: 0;
}

.show {
    display: inline;
}

.menutile {
    display: flex;
    text-align: center;
    height: 130px;
    border-radius: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
}

.fulfillmentArrowDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.fulfillmentTile {
    display: flex;
    height: 130px;
    border-radius: 15px;
    flex-wrap: wrap;
    align-content: flex-end;
    margin-bottom: 15px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
}

.fulfillmentText {
    height: 97px;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    width: 100%;
}

.countText {
    vertical-align: middle;
    line-height: 34.4px;
    width: 100%;
}

.countTile {
    border: 2px solid $textColor-Type6;
    border-radius: 10px;
    box-sizing: border-box;
}

.fulfillmentTextInactive {
    height: 97px;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.fulfillmentTextactive {
    height: 97px;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid $textColor-Type6;
    border-radius: 15px 15px 0px 0px;
}

.wfscreenTitle {
    height: 50px;
    border-radius: 10px;
    @extend .borderColor-Type1;
    border-style: solid;
    box-shadow: 3px 3px $boxShadowColor-Type1;
}

.numberStyle {
    font-size: 20px;
    @extend .textColor-Type6;
}

.wfScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ScanScreen1Header {
    // height: 65px;
    display: flex;
    justify-content: space-between;
}

.Screen1text {
    width: 65%;
}

.Screen4text {
    width: 100%;
    max-width: 213px;
}

.Screen1line {
    width: 2%;
}

.Screen1graph {
    width: 32%;
}

.screen1Graphimg {
    float: left;
    width: 30%;
}

.screen1Graphtxt {
    float: none;
    text-align: right;
    line-height: 0;
}

.ScanScreen1Footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 60px;
    border-radius: 17px;
    line-height: 19px;
    @extend .textColor-Type1;
    /* text-align: center; */
    box-shadow: 5px 5px $boxShadowColor-Type2;
    box-shadow: 0px 8px 10px $boxShadowColor-Type2;
    flex-wrap: wrap;
    align-items: center;
}

.home-button_Footer {
    display: flex;
    justify-content: space-around;
    width: 100%;

    background: #ffffff;
    // border-radius: 17px;
    line-height: 19px;
    @extend .textColor-Type1;
    /* text-align: center; */
    // box-shadow: 5px 5px $boxShadowColor-Type2;
    // box-shadow: 0px 8px 10px $boxShadowColor-Type2;
    flex-wrap: wrap;
    align-items: center;
}


.pickLocationTitle {
    height: 50px;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pickLocationTitledrop {
    // height: 50px;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ExitTile{
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1019607843);
}
.ExitTileSearchGrid{
    border-radius: 10px;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1019607843);
}

.mainScanTile {
    // height: 270px;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 10px 10px $boxShadowColor-Type3;
}

.sp_Pick {
    display: flex;
    justify-content: center;
    padding: 5px;

    label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        /* identical to box height */
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}

.upper_casePack-div {
    display: flex;
    // padding: 10px 10px 20px 10px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.inner_casePack-div {
    display: flex;
    align-items: end;
    // background-color: #FFFFFF;
    min-height: 100px;
    min-width: 23px;
    padding-bottom: 15px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    label {
        display: flex;
        align-items: flex-start;
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        width: 100%;
        height: 100%;
        font-size: 14.3077px;
        // color: #3790D7;
        white-space: nowrap;
        text-transform: uppercase;
        transform: rotate(-90deg);
    }
}

.uperScaner-div {
    display: flex;
    padding: 10px 10px 20px 10px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.uperScaner-div-1 {
    display: flex;
    padding: 6px 0px 39px 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.uperScaner-div-3 {
    display: flex;
    padding: 6px 0px 0px 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.uperScaner-div-4 {
    display: flex;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.scanner-div {
    // height: 85px;
    margin-bottom: 0;
    width: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    // flex-direction: column;
}

.scanner-div-with-2-input {
    margin-bottom: 0;
    width: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
}

.scanner-divWithDate {
    // height: 85px;
    margin-bottom: 0;
    width: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 10px $boxShadowColor-Type3;
    min-height: 80px;
}

.scannerInput {
    font-family: 'Roboto';
    height: 50px;
    width: 95%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 11px;
    outline: none;
}

.MuiGrid-root {
    button {
        display: none !important
    }

}

.scannerInput-4 {
    font-family: 'Roboto';
    height: 50px;
    width: 95%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 11px;
    outline: none;
    border: 1px solid $borderColor-Type5 !important;
}

.inputscan {
    font-family: 'Roboto';
    height: 50px;
    width: 95%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 11px;
    border: none;
    outline: none;
}

.footer.focused {
    /* even more sweet CSS */
    // position: relative !important;
    // margin-top: 100px !important;
    display: none;
}

.uperSD-Text {
    @extend .textColor-Type2;
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
}
.uperSD-Text-conditionSC {
    @extend .textColor-Type2;
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
}

.uperSD-Text {
    @extend .textColor-Type2;
    text-align: center;
}


.uperSD-boundedText {
    border-radius: 10px;
    // height: 61px;
    width: 95%;
    border-style: dashed;
    @extend .borderColor-Type2;
    text-align: center;
    padding: 10px;
    @extend .textColor-Type2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px dashed;
}

.uperSD-boundedText2 {
    border-radius: 25px;
    // height: 61px;
    width: 40%;
    border-style: dashed;
    @extend .borderColor-Type2;
    text-align: center;
    padding: 10px;
    @extend .textColor-Type2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//--------------- DropDown Select -----------------//
.uperScaner-div2 {
    @extend .uperScaner-div;
    // height: 322px !important;
}

.uperScaner-div2 {
    @extend .uperScaner-div;
    // height: 322px !important;
}

.uperSD-boundedText2 {
    @extend .uperSD-boundedText;
    width: 100% !important;
    // height: 130px;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
}

.uperSD-boundedTextHeader {
    display: flex;
    justify-content: space-between;
}

.uperSD-Text2 {
    @extend .uperSD-Text;
    width: 100%;
}

.mainPageType4 {
    height: calc(100vh - 50px);
}

.flexspacebtw {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom-style: solid;
    border-color: $borderColor-Type3;
    border-width: 1px;
}

.dropdown-div {
    height: 43px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 14px;
}

.dropdown-font {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // opacity: 0.6;
}

.dropdown-innerdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        padding-right: 5px;
        padding-left: 70px;
    }
}

.dropdown-optiondiv {
    width: 100%;
    // height: 117px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0 20px;

        li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding: 7px 16px 7px 0px;
            border-bottom: 1px solid #c1c1c1;
        }

        li:last-child {
            border: none;
        }
    }

    .options {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
    }
}

.dropdown-optiondiv-1 {
    // height: 150px;
    // height: 117px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0 20px;

        li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding: 7px 16px 7px 0px;
            border-bottom: 1px solid #c1c1c1;
            line-height: 30px;
        }

        li:last-child {
            border: none;
        }
    }

    .options {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
    }
}

.confirmNoBtn {
    border: 1px solid $textColor-Type1 !important;
    color: $textColor-Type1;
    background-color: transparent;
}

.successAlert {
    background: $backgroundColor-Disable;
    border: 2px solid $borderColor-Type2;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: $textColor-Type4;
    font-size: 14px;
}

.disableTile {
    opacity: 0.5;
    cursor: not-allowed;
    @extend .clickNotAllow
}

.clickNotAllow {
    opacity: 0.5;
    pointer-events: none;
}

.op_5 {
    opacity: 0.5;
}

.enableTile {
    cursor: pointer;
}

.placed-carton {
    border: 1px solid $textColor-Type1;
    border-radius: 10px;
    padding: 10px 20px 8px 14px;
}

.section1 {
    display: flex;
    justify-content: space-between;
    border: 1px solid $textColor-Type1;
    border-radius: 10px;
    filter: drop-shadow(0px 8px 10px $boxShadowColor-Type2);
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-500 {
    font-weight: 500;
}

.pick-locations-title {
    padding: 15px 18px 23px 18px;
    letter-spacing: 0.26px;
}

.pick-locations-title-1 {
    padding: 0px 18px 4px 18px;
    border-bottom: 1px solid $borderColor-Type3;
}

.wrapText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-radius-8 {
    border-radius: 8.33333px;
}

.scancount {
    border: 0.416667px solid $borderColor-Type4;
    border-radius: 4.16667px;
    background: $textColor-Type2;
    padding: 4.17px 13.33px 4.58px 12.92px;
    margin: 17.4px 10.17px 17.26px 10px;
}

.quantity-input {
    width: 80%;
}

.uperScaner-div-3 {
    display: flex;
    padding: 6px 0px 0px 0px;
    border-radius: 25px;
    // border-top-right-radius: 25px;
    // height: calc(270px - 85px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.quantity-input {
    width: 40%;
    border-radius: 25px;
    padding: 10px 10px 10px 10px;
    border: 1px solid $borderColor-Type5;
    outline: none;
    height: 53px;
}

.quantity-inputwithCondition {
    width: 100%;
    // margin-right: 10px;
    border-radius: 25px;
    // padding: 10px 10px 10px 10px;
    border: 1px solid $borderColor-Type5;
    outline: none;
    height: 53px;

    input {
        font-family: 'Roboto';
        height: 50px;
        width: 95%;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 11px;
        border: none;
        outline: none;
    }
}

.serial-number-table {
    border: 1px solid $textColor-Type1;
    padding: 11px 16px 13px 15px;
    border-radius: 25px;
}

.w-30 {
    width: 30% !important;
}

.c-line-height {
    line-height: 0.9;
}

.c-line-height-1 {
    line-height: 1.5;
}

.font-family-inherit {
    font-family: inherit;
}

.index {
    z-index: 0 !important;
}

// For Bottom Slider
#bottom-slide {
    background-color: #fff;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.5rem;
    bottom: -4px;
    height: min-content;
    z-index: 12000;
    border-radius: 25px;
    position: fixed;
    text-align: center;
    width: calc(100% - 17px);
    word-break: break-all;

    p {
        padding: 1.5rem 1rem;
    }

}

#bottom-sheet {
    background-color: #fff;
    padding: 0.5rem 0.5rem;
    bottom: -4px;
    height: min-content;
    z-index: 12000;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: fixed;
    left: 0;
    text-align: center;
    width: 100%;
    font-weight: 100;
    font-size: 1rem;
    max-height: 15rem;
    overflow-y: auto;

    .notch {
        display: inline-block;
        width: 60px;
        height: 4px;
        background: #E8E8E8;
        border-radius: 16px;
    }
}

.sheet-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.329);
    z-index: 10;
}

// For Calender popup
.calenderDrop {
    .MuiDialog-paper {
        border-radius: 0px !important;
        min-height: calc(100% - 538px) !important;
        /* max-width: 600px; */
        width: 100% !important;
        overflow-y: unset !important;
    }

    .MuiDialogActions-root {
        // display: none;
    }
}

// For Success popup
.MuiDialog-paper {
    border-radius: 25px !important;
    min-height: calc(100% - 538px) !important;
    /* max-width: 600px; */
    width: -webkit-fill-available !important;
    overflow-y: unset !important;

    .successMessage,
    .errorMessage {
        display: flex;
        height: 100%;
        width: 100%;
        // box-shadow: 0px 8px 10px rgb(0 0 0 / 5%);
        padding: 25px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-bottom: 8px;
        }

        .locktitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.26px;
            color: #000000;
            justify-content: center;
        }

        label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.26px;
            padding: 10px 10px 0px 10px;
            color: #333333;
            // test ellipsis --------
            max-width: 500px;
            width: 100%;
            max-height: 385px;
            overflow: hidden !important;
            display: -webkit-box;
            -webkit-line-clamp: 15;
            line-clamp: 15;
            -webkit-box-orient: vertical;

        }

        input {
            margin-top: 12px;
        }
    }
}

.warehouse-holder {
    border: 1px solid #000000;
    border-radius: 20px;
    display: flex;
    align-items: center;

    .warehouse {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
    }
}

.dropped-pkg-holder {
    background: rgba(57, 146, 216, 0.15);
    border: 1px solid #3992D8;
    border-radius: 25px;

    .drop-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        color: #333333;
    }
}

// For Warehouse selection panel
.warehouse-panel {
    z-index: 11111;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    @extend .cpl-10,
    .cpr-10;
    padding-bottom: 70px;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    margin: 0 !important;
    margin-top: 70px !important;
    background: #ffffff !important;
    border-radius: 25px 25px 0px 0px !important;

    .warehouse-panel-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        @extend .cpb-20;

        span {
            width: 60px;
            border-top: 4px solid #e8e8e8;
            @extend .cpb-20;
        }

        label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
        }
    }

    .warehouse-panel-items {
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .warehouse-panel-list {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.26px;
        color: #000000;
        padding: 15px;
        border-top: 3px solid #f8f4f4;
        width: 100%;
    }

    .warehouse-panel-list:last-of-type {
        label {
            height: 20px;
        }

    }
}

.error-field {
    border: 2px solid $textColor-Error;
}

.field {
    border: 2px solid transparent;
}

.passInput {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
    /* align-content: space-between; */
    align-items: center;
    // padding: 0%;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px#f7f7f7 inset !important;
}

.confirmtile {
    padding: 70px 10px 71px 10px;
}

.timer_outer {
    position: absolute;
    border-radius: 50px;
    background-color: rgba(116, 196, 228, 0.26);
    padding: 6px;

    .timer_inner {
        border-radius: 50px;
        background: linear-gradient(144.64deg, #74C4E4 12.02%, #358ED7 90.14%);
        padding: 10px;

        svg {
            color: $textColor-Type2;
        }
    }
}

.timer-footer {
    // position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.pause_timer {
    width: 70% !important;
    height: 35px !important;
    display: none;
}

.text_Flashing {
    animation: text_Flashing 3.5s step-end infinite;
    border-radius: 5px;
}

@keyframes text_Flashing {
    0% {
        background-color: $textColor-Type2;
    }

    25% {
        background-color: $textColor-Type7;
    }

    50% {
        background-color: $textColor-Type2;
    }

    75% {
        background-color: $textColor-Type7;
    }
}

.BG_Flashing {
    animation: BG_Flashing 3.5s step-end infinite;
}

@keyframes BG_Flashing {
    0% {
        background-color: $textColor-Type2;
    }

    25% {
        background-color: $textColor-Type7;
    }

    50% {
        background-color: $textColor-Type2;
    }

    75% {
        background-color: $textColor-Type7;
    }
}

// custom loading screen
section#loading-comp {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2.5px);
    z-index: 1200;
}

section#loading-comp img {
    width: 200px;
}

.place-carton {
    position: fixed;
    z-index: 0;
}

footer.home-btn-footer {
    position: relative;
}

footer.home-btn-footer::before,
footer.home-btn-footer::after {
    content: "";
    position: fixed;
    bottom: 0;
    width: calc(100% - 530px);
    height: 49px;
    background: white;
    // background: greenyellow;
    z-index: 10;
}

footer.home-btn-footer::before {
    left: 0;
}

footer.home-btn-footer::after {
    right: 0;
}

#home-footer {
    position: fixed;
    bottom: 0;
    z-index: 100;
}


// Sidebar component CSS 
.navbar {
    background-color: $borderColor-Type5;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.menu-subbars {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.menu-subbars .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}

.menu-subbars .detail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #FFFFFF;
}

.menu-avatar {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* border: 7px solid transparent; */
    background-color: rgba(255, 255, 255, 0.2);
    align-items: center;
    text-align: center;
    justify-content: center;

    label {
        background: linear-gradient(0deg, #E7EDF7, #E7EDF7);
        padding: 20px 18px 20px 18px;
        border-radius: 50%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        color: #3F7DDB;
    }
}
// client side list
.client-menu {
    background: #F2F5FA;
    // border-radius: 0px 20px 100px 0px;
    // width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -150%;
    transition: 850ms;
    z-index: 2;
}

.client-menuactive {
    left: 0;
    transition: 350ms;
}
.clientTable {
    .title {
        display: flex;
        // justify-content: space-around;
        align-items: center;
        div{
            width: 150px;
            margin-right: 10px;
        }
    }

    table {
        background-color: transparent
    }

    .detail {
        display: flex;
        // justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
        div{
            width: 150px;
            margin-right: 10px;
        }
    }

}

.nav-menu {
    background: linear-gradient(295.5deg, #74C4E4 1.48%, #358ED7 63.39%);
    border-radius: 0px 20px 100px 0px;
    width: 310px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 2;
}

.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 16px 4px 12px;
    list-style: none;
    height: 40px;
}

.nav-text span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-decoration: none;
    color: #FFFFFF;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 28px;
    border-radius: 4px;
}

.nav-text span:hover {
    background-color: #FFFFFF;
    border-radius: 20px;
    color: #000000;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
}

.menu_bd {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.navbar-toggle {
    background-color: var(--navBg);
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-bottom: 20px;
}

.logout {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.logout span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: 16px;
}


.backgroundColor-Gradient.uperScaner-div2 {
    max-height: 420px !important;
    height: 100% !important;
    overflow-y: auto;
}

.uperSD-Text2 {
    max-height: 114px !important;
    height: 100% !important;
}

.iframe {
    position: absolute;
    width: 100%;
    height: calc(100vh - 110px);
    z-index: 100;
}

.uperSD-Text {
    color: #000000 font
}


.setting-outer-padding {
    padding: 40px 30px;
}

.about-footer {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 40px;
}

/* HIDE RADIO */
[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
    cursor: pointer;
    display: none;
}

/* CHECKED STYLES */
[type=radio]:checked+img {
    display: block;
}

//// ****************** Material UI Toggle Button**************/////

span.MuiSwitch-thumb {
    position: relative;
    display: grid;
    place-content: center;

}

.MuiSwitch-switchBase+.MuiSwitch-track {
    background-color: #fff !important;
    border: 1px solid rgba(0, 0, 0, .25);
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    opacity: 1 !important;
    background-color: #74C4E4 !important;
    border: 1px solid rgba(0, 0, 0, 0);
}

span.MuiSwitch-thumb {
    background-color: rgba(0, 0, 0, .25) !important;
}

.Mui-checked span.MuiSwitch-thumb {
    background-color: #fff !important;
}


span.MuiSwitch-thumb::after {
    content: "-";
    color: #fff;


}

.Mui-checked span.MuiSwitch-thumb::after {
    content: "+";
    color: #74C4E4;
}



element.style {
    width: 500px;
    overflow: scroll;
}

table {
    caption-side: bottom;
    border-collapse: unset !important;
    border-spacing: 0px !important;
}

.tableColumn {
    width: 100%;
    padding: 10px;
}

.textalignCenter {
    text-align: center;
}

.accountSelectionHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;

}

.homeButton {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
}

.homeButtonDiv {
    margin-right: 12.5px;
    margin-left: 12.5px;
    margin-bottom: 10px;

}

// Table classes for account selection
table {
    background-color: $textColor-Type2;
}

// Inquiries

.Inquiry {
    .title {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    table {
        background-color: transparent
    }

    .detail {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
    }

}

.search_holder {
    border-bottom: 1px solid #E3E3E3;
    ;
    background-color: transparent;
}

.search_input {
    width: 100%;
    // padding: 13px 10px 13px 10px;
    border: none;
    outline: none;
    background-color: transparent;

    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.26px;

    /* Light Deactive Text Color */

    color: #A6A6A6;
}

.left-right {
    &>div:last-child {
        text-align: right;
    }
}

.floating-search {
    padding: 15px 12px;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    width: calc(100% - 35px);

    input {
        all: unset;
        width: 100%;
        font-weight: 500;
    }

    .search-wrapper {
        background: #F6F6F6;
        border-radius: 25px;
        display: flex;
        padding: 14px;
        width: 100%;

        &:focus-within {
            outline: 2px solid #48D2A0;
        }
    }
}

input.outline-btn {
    color: #333;
    outline: 1px solid #333;
    background: transparent;
}

.outLine {
    outline: 2px solid #74C4E4;
}

.homeButtonBorder {
    border-radius: 17px;
}

input::placeholder {
    color: #00000045;
}

.search-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0 20px;

        li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding: 7px 16px 7px 0px;
            line-height: 30px;
        }

        li:last-child {
            border: none;
        }
    }

    .options {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
    }
}

.relative {
    position: relative;
}

#progressive-search {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    margin: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 14px;
    background-color: #fff;
    max-height: 165px;
    height: max-content;
    overflow-y: auto;

    p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        padding: 4px 10px;
    }
}

.MuiAlert-message{
    max-width: 500px;
    width: 100%;
    height: 50px;   
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
// ===================== List component =============== //
// this will if the list column is empty then hide it.  //
// ==================================================== //
.tableColumn:empty {
    display: none;
}

div#document-option {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    border-radius: 20px;
    border: 0.5px solid rgba(52, 73, 94, 0.3);
}

.grid-layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rounded-4 {
    border-radius: 10px;
}

#order-table {
    font-family: 'Roboto', sans-serif;
    tr:first-child {
        font-weight: bold;

        td {
            padding: 10px 5px;
        }
    }
    
    td {
        border-bottom: 1px solid #F8F4F4;
        padding: 5px;
    }

    tr td:nth-of-type(2) {
        text-align: left;
    }
}

.headerMain2 {
    .left {
        display: flex;
        align-items: center;
        gap: 5px;

        label {
            display: block;
            width: 152px;
        }
    }
}