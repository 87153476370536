$textColor-Type1: #000000;
$textColor-Type2: #ffffff;
$textColor-Type3: #4a4a4a;
$textColor-Type4: #a6a6a6;
$textColor-Type5: #358ed7;
$textColor-Type6: #74c4e4;
$textColor-Type7: #DF604F;
$textColor-Type8: #333333;
$textColor-Error: #d32f2f;
$textColor-LoginError: #E45353;
$backgroundColor-Type1: #f7f7f7;
$backgroundColor-Type2: #e5e5e5;
$backgroundColor-Type4: #f6f6f6;
$backgroundColor-Type5: #F2F5FA;
$backgroundColor-Type6: #E2EDFC;
$backgroundColor-Type7: #48D2A0;
$backgroundColor-Disable: #F5F5F5;
$borderColor-Type1: #A6A6A6;
$borderColor-Type2: #4FDF89;
$borderColor-Type3: #ffffff33;
$borderColor-Type4: #C9C9C9;
$borderColor-Type5: #109CF1;
$boxShadowColor-Type1: #add8e6;
$boxShadowColor-Type2: #0000000d;
$boxShadowColor-Type3: #0000001a;
:export {
    $textColor-Type1: $textColor-Type1;
    $textColor-Type2: $textColor-Type2;
    $textColor-Type3: $textColor-Type3;
    $textColor-Type4: $textColor-Type4;
    $textColor-Type5: $textColor-Type5;
    $textColor-Type6: $textColor-Type6;
    $textColor-Type7: $textColor-Type7;
    $textColor-Type8: $textColor-Type8;
    $textColor-Error: $textColor-Error;
    $textColor-LoginError: $textColor-LoginError;
    $backgroundColor-Type1: $backgroundColor-Type1;
    $backgroundColor-Type2: $backgroundColor-Type2;
    $backgroundColor-Type4: $backgroundColor-Type4;
    $backgroundColor-Type5: $backgroundColor-Type5;
    $backgroundColor-Type6: $backgroundColor-Type6;
    $backgroundColor-Type7: $backgroundColor-Type7;
    $backgroundColor-Disable: $backgroundColor-Disable;
    $borderColor-Type1: $borderColor-Type1;
    $borderColor-Type2: $borderColor-Type2;
    $borderColor-Type3: $borderColor-Type3;
    $borderColor-Type4: $borderColor-Type4;
    $borderColor-Type5: $borderColor-Type5;
    $boxShadowColor-Type1: $boxShadowColor-Type1;
    $boxShadowColor-Type2: $boxShadowColor-Type2;
    $boxShadowColor-Type3: $boxShadowColor-Type3;
}
